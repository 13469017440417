/** @jsxImportSource theme-ui */

import { Nav, Section, ContactUs, BnA } from '../Components';


const Home = () => (
    <div sx={{
        backgroundImage: 'url(/Asset/Splash.jpg)',
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "inherit",
        backgroundSize: ["100vw 60vh ", "cover", "cover"]
        }}>
        <Nav />
        <Section 
            heading="Robert's PDR, LLC" 
            para="Robert's PDR is a local paintless Dent repair company. We can come to your home or office to repair your dents. Serving Xenia,
             Springfield, Dayton and surrounding areas. Call or text Robert at 937-532-4884" 
            />
        <Section 
            heading="PDR stands for Paintless Dent Repair." 
            para="PDR is the art of Removing dings and dents using various tools and techniques instead of body filler and paint.
            It is done by using a special light to see the dent and see where the tip of the tool is then pushing the dent up a little at a time.
            The technician will need to gain access to the back side of the Dent, which means in some cases carefully Removing the tail light,  head light,
             inner trim panel, or bumper.
            If there is no access to the Dent it will need to be glue pulled by using a glue tab along with hot glue and a slide hammer or Lifter.
            Glue pulling can only be done if the panel being repaired has the original factory paint.
            In most cases PDR is cheaper than traditional body work and can be done in a fraction of the time. " 
            />
        <BnA />
        <ContactUs 
            email="robertspdrllc@gmail.com"
            phone="(937) 532-S4884"
            />
    </div>
)

export default Home;
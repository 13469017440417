/** @jsxImportSource theme-ui */
import { Container, Heading, Paragraph } from 'theme-ui'


const ContactUs = ({email, phone}) => (
    <Container p={[4, 5, 6]} bg="muted" sx={{backgroundColor: "Black", color: "whitesmoke", minHeight:"20vh"}}>
        <Heading mb={[2,4,4]}>Contact Us</Heading>
        <Heading as='h3' mb={[2,4,4]} >Email</Heading>
        <Paragraph mb={[2,4,4]} >{email}</Paragraph>
        <Heading as='h3' mb={[2,4,4]} >Phone</Heading>
        <Paragraph mb={[2,4,4]} >{phone}</Paragraph>
    </Container>
)

export default ContactUs;
/** @jsxImportSource theme-ui */
import { NavLink, Flex, Container } from 'theme-ui'


const Nav = () => (
    <Flex as="nav" sx={{backgroundColor: "white", marginBottom:["7", "8", "8"], fontSize: ["1.1em", "1.25em", "1.5em"]}}>
        <Container pl={[1, 4, 6]} pr={[1, 4, 6]} bg="muted" >
            <NavLink href="#!" p={2}>
                Home
            </NavLink>
            <NavLink href="#!" p={2}>
                Blog
            </NavLink>
            <NavLink href="#!" p={2}>
                About
            </NavLink>
        </Container>
    </Flex>
)

export default Nav;
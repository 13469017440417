/** @jsxImportSource theme-ui */
import { Container, Heading, Paragraph } from 'theme-ui'


const Section = ({heading, para}) => (
    <Container p={[4, 5, 6]} bg="muted" sx={{backgroundColor: "white", minHeight:"25vh"}}>
        <Heading mb={[1,4,4]} sx={{fontSize: ["2em", "2.5em", "3em"]}} >{heading}</Heading>
        <Paragraph sx={{fontSize: ["1.5em", "1.75em", "2em"]}}>{para}</Paragraph>
    </Container>
)

export default Section;